import React, { useEffect, useState } from "react";
// import cloneDeep from 'lodash.clonedeep';
import ProgressBar from "@ramonak/react-progress-bar";
import {
  BiFileBlank,
  BiPlay,
  BiPlusCircle,
  BiSave,
  BiTrash,
  BiUpload,
} from "react-icons/bi";
import { GiConfirmed } from "react-icons/gi";
import { useParams } from "react-router-dom";
import {
  CustomMaterial,
  GeneralApi,
  MaterialDeformationApi,
  MdSolveData,
} from "../../../api";
import Selector from "../../../components/Selector";
import Header from "../../../components/header/Header";
import { FAST } from "../../../fast-solve";
import { BCSetting } from "./BCSetting";
import { LoadSetting } from "./LoadSetting";
import MaterialTour from "./MaterialDeformationTour";
import * as empty from "./emptyInitializers";
import * as Styled from "./style";

type ProjectIds = {
  id: string;
  name: string;
};

export const emplacements = ["left", "right"];

const api = new MaterialDeformationApi();
const generalApi = new GeneralApi();

function uniqueId(prefix = "id-") {
  return prefix + Math.random().toString(16).slice(-4);
}

function MaterialDeformation() {
  // TODO: maybe we don't need that many useStates

  const [project, setProject] = useState(empty.project);
  const [options, setOptions] = useState(empty.options);
  const [results, setResults] = useState(empty.mdResult);
  // const [results, setResults] = useState<MdResult>();
  const [progress, setProgress] = useState(0);
  const [projects, setProjects] = useState<ProjectIds[]>([]);
  const [renaming, setRenaming] = useState(false);
  const [tempProjectName, setTempProjectName] = useState("");
  const [finishedProject, setFinishedProject] = useState(false);

  const params = useParams();
  let state: any;
  try {
    params.state && (state = JSON.parse(params.state));
  } catch {
    state = null;
  }

  let progressInterval: NodeJS.Timer;

  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }
  useEffect(() => {
    api.getOptions(idToken).then((res) => setOptions(res.data));
    if (state?.action === "read") {
      setFinishedProject(true);
      projectFromJobId(state.id);
    } else if (state?.action === "run") {
      projectFromJobId(state.id);
      startSimulation(project.parameters);
    }
  }, []);

  function refreshProgress(jobId: string) {
    generalApi.getJob(jobId, idToken).then((res) => {
      const localProgress = res.data.progress;
      setProgress(localProgress);
      if (localProgress === 100) {
        api.getMdResults(jobId, idToken).then((res) => setResults(res.data));
        clearInterval(progressInterval);
        setTimeout(() => setProgress(0), 1000);
      }
    });
  }

  function startSimulation(solveData: MdSolveData) {
    progressInterval && clearInterval(progressInterval);
    setResults(empty.mdResult); // undefined
    console.log(FAST);
    api.solveMd(solveData, idToken, FAST).then((res) => {
      refreshProgress(res.data);
      progressInterval = setInterval(() => refreshProgress(res.data), 1000);
    });
  }

  function setProjectValue(valueName: keyof MdSolveData, value: any) {
    project.parameters[valueName] = value;
    setProject({ ...project });
  }

  function saveProject() {
    if (project.name === "") setRenaming(true);
    else api.saveProject(project.id, project, idToken);
  }

  function choseProject() {
    api.getProjects(idToken).then((res) => setProjects(res.data));
  }

  function loadProject(projectId: string) {
    api.getProject(projectId, idToken).then((res) => setProject(res.data));
    setProjects([]);
  }

  function newLoad() {
    const id = uniqueId("l-");
    const newLoad = { ...empty.load, id: id };
    setProjectValue("loads", [...project.parameters.loads, newLoad]);
  }

  function newBCond() {
    const id = uniqueId("bc-");
    const newBCond = { ...empty.bCond, id: id };
    setProjectValue("boundaryConditions", [
      ...project.parameters.boundaryConditions,
      newBCond,
    ]);
  }

  function projectFromJobId(jobId: string) {
    generalApi.getJobProject(jobId, idToken).then((res) => {
      setProject(res.data);
    });
    api.getMdResults(jobId, idToken).then((res) => setResults(res.data));
  }

  async function newProject() {
    setFinishedProject(false);
    setResults(empty.mdResult);
    setProject({ ...empty.project });
  }

  async function renameProject() {
    if (tempProjectName !== "") {
      let newId: string;
      if (project.id === "none")
        newId = await api.getNewProjectId(idToken).then((res) => res.data);
      else newId = project.id;
      let proj = { ...project, name: tempProjectName, id: newId };
      setProject(proj);
      api.saveProject(proj.id, proj, idToken);
    }

    setRenaming(false);
    setTempProjectName("");
  }

  function deleteProject(projectId: string) {
    api.deleteProject(projectId, idToken);
    setProjects(projects.filter((proj) => proj.id !== projectId));
  }

  function selectMaterial(material: string) {
    setProjectValue("material", material);
    if (material === "custom" && !project.parameters.customMaterial)
      setProjectValue("customMaterial", { ...empty.customMaterial });
  }

  return (
    <React.Fragment>
      <Header />
      <Styled.Container>
        <Styled.Side>
          <Styled.ProjectName>
            {project.name === "" ? (
              <Styled.UnnamedProject>Unnamed project</Styled.UnnamedProject>
            ) : (
              project.name
            )}
            {!finishedProject && (
              <Styled.RenameButton
                title="Rename project"
                onClick={() => {
                  project.name && setTempProjectName(project.name);
                  setRenaming(true);
                }}
                disabled={finishedProject}
              >
                <Styled.RenameIcon />
              </Styled.RenameButton>
            )}
          </Styled.ProjectName>
          <div id="Project">
            <Styled.Project>
              {!finishedProject && (
                <>
                  <Styled.RunButton
                    title="Run project"
                    onClick={() => startSimulation(project.parameters)}
                  >
                    <BiPlay />
                  </Styled.RunButton>
                  <Styled.Button title="Save project" onClick={saveProject}>
                    <BiSave />
                  </Styled.Button>
                </>
              )}
              <Styled.Button title="Load project" onClick={choseProject}>
                <BiUpload />
              </Styled.Button>
              <Styled.Button title="New project" onClick={newProject}>
                <BiFileBlank />
              </Styled.Button>
            </Styled.Project>
          </div>
          {/* <Styled.Header> Shapes </Styled.Header>
                    <Selector 
                        currentValue={activeOption.shape} 
                        setCurrentValue={(shape) => setActiveOption({...activeOption, shape: shape})} 
                        availableValues={options.shapes}
                        defaultValue={'Select shape'}
                        removeDefault={true}
                    /> */}
          {/* <ImportButton accept=".json" handleFile={handleFile}/> */}
          <Styled.Header>Materials</Styled.Header>
          <Selector
            name="Materials"
            currentValue={project.parameters.material}
            setCurrentValue={selectMaterial}
            availableValues={
              options.materials
            } /* TODO: improve esthetic by capitalizing the first letter */
            defaultValue={"Select material"}
            removeDefault={true}
            disabled={finishedProject}
          />
          <Styled.CustomMaterial
            visible={project.parameters.material === "custom"}
          >
            <h4>Material Parameters</h4>
            {project.parameters.customMaterial &&
              (
                Object.keys(empty.customMaterial) as (keyof CustomMaterial)[]
              ).map((param) => {
                const humanizedParamName = param.replace(/([A-Z])/g, " $1");
                return (
                  <div key={param}>
                    <label htmlFor={param}>{humanizedParamName}</label>
                    <Styled.FInput
                      name={param}
                      location={
                        project.parameters.customMaterial as {
                          [x in keyof CustomMaterial]: number;
                        }
                      }
                      coord={param}
                      disabled={finishedProject}
                      setter={(val) => setProjectValue("customMaterial", val)}
                    />
                  </div>
                );
              })}
          </Styled.CustomMaterial>

          {/* <Styled.Header>Algorithms</Styled.Header>
                    <Selector
                        currentValue={activeOption.algorithm}
                        setCurrentValue={(algorithm) => setActiveOption({ ...activeOption, algorithm: algorithm })}
                        availableValues={options.algorithms}
                        defaultValue={'Select algorithm'}
                        removeDefault={true}
                    /> */}

          <Styled.Header>Noise model</Styled.Header>
          <div id="Noise_and_simulations">
            <Selector
              currentValue={project.parameters.noiseModel}
              setCurrentValue={(noiseModel) =>
                setProjectValue("noiseModel", noiseModel)
              }
              availableValues={options.noiseModels}
              defaultValue={"Select noise"}
              removeDefault={true}
              disabled={finishedProject}
            />
          </div>
          <div id="Tour">
            <Styled.Header>
              <MaterialTour />
            </Styled.Header>
          </div>
        </Styled.Side>
        <Styled.Center>
          {progress !== 0 && <ProgressBar completed={progress} />}
          {results !== empty.mdResult && (
            <Styled.ResultImage
              src={`data:image/png;base64,${results.displacement}`}
            />
          )}
          {results !== empty.mdResult && (
            <Styled.ResultImage
              src={`data:image/png;base64,${results.stress}`}
            />
          )}
        </Styled.Center>
        <Styled.Side>
          <Styled.SubHeader>Loads</Styled.SubHeader>
          <Styled.MultipleParameterDiv id="Load">
            {project.parameters.loads.map((load, index) => (
              <LoadSetting
                key={load.id}
                loads={project.parameters.loads}
                setLoads={(loads) => setProjectValue("loads", loads)}
                index={index}
                disabled={finishedProject}
              />
            ))}
            {!finishedProject && (
              <Styled.Button
                title="Add load"
                onClick={newLoad}
                disabled={finishedProject}
              >
                <BiPlusCircle />
              </Styled.Button>
            )}
          </Styled.MultipleParameterDiv>
          <Styled.Header>Boundary Conditions</Styled.Header>
          <Styled.MultipleParameterDiv id="BC">
            {project.parameters.boundaryConditions.map((bCond, index) => (
              <BCSetting
                key={bCond.id}
                bConds={project.parameters.boundaryConditions}
                index={index}
                setBConds={(bConds) =>
                  setProjectValue("boundaryConditions", bConds)
                }
                disabled={finishedProject}
              />
            ))}
            {!finishedProject && (
              <Styled.Button
                title="Add boundary condition"
                onClick={newBCond}
                disabled={finishedProject}
              >
                <BiPlusCircle />
              </Styled.Button>
            )}
          </Styled.MultipleParameterDiv>
        </Styled.Side>
      </Styled.Container>
      <Styled.ModalBackground hide={projects.length === 0 && !renaming}>
        <Styled.ModalContent hide={projects.length === 0}>
          <Styled.Close onClick={() => setProjects([])}>&times;</Styled.Close>
          <h1>Choose project</h1>
          {projects.map((proj) => (
            <Styled.ProjectChoice key={proj.id}>
              <Styled.Project
                onClick={() => {
                  setFinishedProject(false);
                  setResults(empty.mdResult);
                  loadProject(proj.id);
                }}
              >
                {proj.name}
              </Styled.Project>
              <Styled.Button
                title="Delete project"
                onClick={() => deleteProject(proj.id)}
              >
                <BiTrash />
              </Styled.Button>
            </Styled.ProjectChoice>
          ))}
        </Styled.ModalContent>
        <Styled.ModalContent hide={!renaming}>
          <Styled.Close onClick={() => setRenaming(false)}>
            &times;
          </Styled.Close>
          <h1>Please name project</h1>
          <Styled.Input
            value={tempProjectName}
            onChange={(e) => setTempProjectName(e.target.value)}
          />
          <Styled.BlackButton onClick={renameProject}>
            <GiConfirmed />
          </Styled.BlackButton>
        </Styled.ModalContent>
      </Styled.ModalBackground>
    </React.Fragment>
  );
}

export default MaterialDeformation;
